import api from '../axios';
const resource = '/api/mp/';

export default {
  getUnsubmitted: (page, paginate, filterQuery) =>
    api.get(
      `${resource}salaried-staff/time_sheets/not-submitted?page=${page}&paginate=${paginate}&${filterQuery}`
    ),
  getSummary: () => api.get(`${resource}salaried-staff/time_sheets/summary`),
  submittTimesheet: (payload) =>
    api.post(`${resource}salaried-staff/time_sheets/shifts/submit`, payload),

  updateTimesheet: (id, payload) =>
    api.put(
      `${resource}salaried-staff/time_sheets/shifts/${id}/update`,
      payload
    ),

  getSubmitted: (page, paginate) =>
    api.get(
      `${resource}salaried-staff/time_sheets/shifts/submitted?page=${page}&paginate=${paginate}`
    ),
};
