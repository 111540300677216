var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c("b-col", { staticClass: "d-flex", attrs: { cols: "" } }, [
            _c(
              "div",
              [
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "input-group-merge" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "CalendarIcon" }
                            })
                          ],
                          1
                        ),
                        _c("flat-pickr", {
                          staticClass: "form-control",
                          attrs: {
                            config: {
                              maxDate: "today",
                              enableTime: false,
                              noCalendar: false,
                              dateFormat: "Y-m-d"
                            },
                            placeholder: "Filter by date"
                          },
                          model: {
                            value: _vm.filters["start"],
                            callback: function($$v) {
                              _vm.$set(_vm.filters, "start", $$v)
                            },
                            expression: "filters['start']"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-auto mb-1" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.top",
                        value: "Download",
                        expression: "'Download'",
                        modifiers: { hover: true, top: true }
                      }
                    ],
                    staticClass:
                      "btn-icon bg-white text-primary shadow-sm font-weight-bold mr-1",
                    attrs: { size: "23", variant: "white" }
                  },
                  [
                    _c("feather-icon", {
                      attrs: { size: "18", icon: "DownloadIcon" }
                    })
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "bg-dark text-white",
                    attrs: {
                      size: "20",
                      variant: "outline-white",
                      disabled: _vm.timesheetIds.length == 0
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitAllTimesheet()
                      }
                    }
                  },
                  [_vm._v(" Submit All ")]
                )
              ],
              1
            )
          ]),
          _c(
            "b-col",
            { staticClass: "mt-1", attrs: { cols: "12" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                [
                  _c("b-table", {
                    ref: "table",
                    staticClass: "mobile_table_css",
                    attrs: {
                      "current-page": _vm.currentPage,
                      "per-page": _vm.pagination.perPage,
                      fields: _vm.fields,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                      items: _vm.getUnsubmittedTimesheet,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "sort-direction": _vm.sortDirection,
                      hover: "",
                      responsive: ""
                    },
                    on: {
                      "update:sortBy": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function($event) {
                        _vm.sortDesc = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(time)",
                        fn: function(data) {
                          return [
                            data.item.shift.start !== data.item.start ||
                            data.item.shift.end !== data.item.end
                              ? _c("div", { staticClass: "pt-3" }, [
                                  _c("del", [
                                    _vm._v(" " + _vm._s(data.item.time) + " ")
                                  ]),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "alert alert-primary d-flex font-weight-bold",
                                      staticStyle: { padding: "5px" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: " text-dark" },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              size: "15",
                                              icon: "Edit2Icon"
                                            }
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.momentFormat(
                                                  data.item.start,
                                                  "HH:mm"
                                                )
                                              ) +
                                              " - " +
                                              _vm._s(
                                                _vm.momentFormat(
                                                  data.item.end,
                                                  "HH:mm"
                                                )
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  _vm._v(" " + _vm._s(data.item.time) + " ")
                                ])
                          ]
                        }
                      },
                      {
                        key: "cell(hours)",
                        fn: function(data) {
                          return [
                            data.item.hours !== data.item.shift_duration
                              ? _c("div", { staticClass: "pt-3" }, [
                                  _c("del", [
                                    _vm._v(
                                      " " +
                                        _vm._s(data.item.shift_duration) +
                                        " "
                                    )
                                  ]),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "alert alert-primary d-flex font-weight-bold",
                                      staticStyle: { padding: "5px" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: " text-dark" },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              size: "15",
                                              icon: "Edit2Icon"
                                            }
                                          }),
                                          _vm._v(
                                            " " + _vm._s(data.item.hours) + " "
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ])
                              : _c("div", [
                                  _vm._v(" " + _vm._s(data.item.hours) + " ")
                                ])
                          ]
                        }
                      },
                      {
                        key: "cell(commentary)",
                        fn: function(data) {
                          return [
                            data.item.commentary
                              ? _c(
                                  "b-row",
                                  {
                                    staticClass:
                                      "center text-center text-primary"
                                  },
                                  [
                                    _c("feather-icon", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value: data.item.commentary,
                                          expression: "data.item.commentary",
                                          modifiers: { hover: true }
                                        }
                                      ],
                                      staticClass: "ml-3",
                                      attrs: {
                                        size: "20",
                                        icon: "MessageCircleIcon"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "cell(actions)",
                        fn: function(data) {
                          return [
                            _c(
                              "b-row",
                              { staticClass: "center text-primary" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass:
                                      "btn-icon bg-white text-primary shadow-sm font-weight-bold mr-1",
                                    attrs: { variant: "white" },
                                    on: {
                                      click: function($event) {
                                        return _vm.toggleSideBars(data.item)
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "EditIcon", size: "20" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "bg-primary text-white",
                                    attrs: {
                                      size: "",
                                      variant: "outline-white"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.submitTimesheet(data.item.id)
                                      }
                                    }
                                  },
                                  [_vm._v(" Submit ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm.unsubmittedTableLenght === 0
                    ? _c("div", { staticClass: "text-center p-5" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "220",
                              height: "220",
                              viewBox: "0 0 160 160",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z",
                                fill: "#F0F8FC"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M66.1895 87.4937H92.1618M66.1895 100.484H83.5058",
                                stroke: "#1B9AAA",
                                "stroke-width": "3",
                                "stroke-linecap": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M81.3234 40.7456H54.3247C50.8315 40.7456 48 43.5763 48 47.0666V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0303",
                                stroke: "#171822",
                                "stroke-width": "3",
                                "stroke-linecap": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.47 62.0764 99.546 62.0764C92.6218 62.0764 87.0078 56.4629 87.0078 49.5382C87.0078 42.6135 92.6218 37 99.546 37C101.267 37 102.91 37.3472 104.403 37.9755",
                                stroke: "#171822",
                                "stroke-width": "2.5",
                                "stroke-linecap": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M109.825 42.3568C109.156 41.4007 108.356 40.5423 107.453 39.8071",
                                stroke: "#171822",
                                "stroke-width": "2.5",
                                "stroke-linecap": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M98.7554 43.0996V50.1572C98.7554 50.2105 98.7983 50.2536 98.8525 50.2536H104.855",
                                stroke: "#1B9AAA",
                                "stroke-width": "2.5",
                                "stroke-linecap": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M137.872 55.3193C140.221 55.3193 142.127 57.2249 142.127 59.5747C142.127 61.9244 140.221 63.83 137.872 63.83C135.522 63.83 133.616 61.9244 133.616 59.5747C133.616 57.2249 135.522 55.3193 137.872 55.3193Z",
                                fill: "#DCEEF8"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M149.788 41.7021C153.078 41.7021 155.745 44.3694 155.745 47.6596C155.745 50.9498 153.078 53.617 149.788 53.617C146.498 53.617 143.831 50.9498 143.831 47.6596C143.831 44.3694 146.498 41.7021 149.788 41.7021Z",
                                fill: "#DCEEF8"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z",
                                fill: "#DCEEF8"
                              }
                            })
                          ]
                        ),
                        _c("br"),
                        _c("div", { staticClass: "mt-lg-1 text-large" }, [
                          _vm._v(
                            "You’re all caught up! There is nothing to submit."
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-start",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _vm.pagination.totalRows !== 0
                    ? _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Showing " +
                            _vm._s(_vm.pagination.from) +
                            " to " +
                            _vm._s(_vm.pagination.to) +
                            " of " +
                            _vm._s(_vm.pagination.totalRows) +
                            " entries"
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center justify-content-sm-end",
                  attrs: { cols: "12", sm: "6" }
                },
                [
                  _c("b-pagination", {
                    staticClass: "mb-0 mt-1 mt-sm-0 ",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      "total-rows": _vm.pagination.totalRows,
                      "first-number": "",
                      "last-number": "",
                      "next-class": "next-item",
                      "prev-class": "prev-item"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prev-text",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronLeftIcon", size: "18" }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "next-text",
                        fn: function() {
                          return [
                            _c("feather-icon", {
                              attrs: { icon: "ChevronRightIcon", size: "18" }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("EditUserSideBar", {
            ref: "EditUserSideBar",
            attrs: {
              "is-timesheet-edit-side-bar-active":
                _vm.isTimesheetEditSideBarActive,
              "timesheet-data": _vm.selectedTimesheet
            },
            on: {
              toggleSideBars: function($event) {
                return _vm.toggleSideBars()
              },
              dataRefresh: function($event) {
                return _vm.dataRefresh()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }